<template>
    <div>
        <b-container>
            <b-row class="mt-3">
                <b-col>
                    <h5>商品報告</h5>
                    <p>顯示開業以來的商品報告</p>
                </b-col>
                <b-col>
                    <b-form-select class="mt-3" v-model="shop" :options="shopOptions"></b-form-select>
                </b-col>
            </b-row>
            <b-button-group>
                <label class="pt-1 pr-2">日結</label>
                <b-form-checkbox v-model="isChecked" switch size="lg" @change="endDate = null" />
                <label class="pt-1">月結</label>
            </b-button-group>
            <b-form-group label="開始日期">
                <b-form-datepicker v-model="startDate" />
            </b-form-group>
            <b-form-group v-if="isChecked" label="結束日期">
                <b-form-datepicker v-model="endDate" />
            </b-form-group>
            
            <b-table :fields="fields" :items="productByOrders" hover responsive/>
        </b-container>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
    data() {
        return {
            shop: null,
            isChecked: false,
            startDate: null,
            endDate: null,
            fields: [
                {
                    key: 'name',
                    label: '產品名稱',
                    sortable: true,
                },
                {
                    key: 'count',
                    label: '數量',
                    formatter: value => {
                        return value ? `${value}件` : `0件`
                    },
                    sortable: true,
                },
                {
                    key: 'total',
                    label: '小計',
                    formatter: value => {
                        return value ? `$${Math.round(value *10)/10}` : `$0`
                    },
                    sortable: true,
                },
            ],
        }
    },

    async created() {
        this.$store.state.endDate = null
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'products', merchantId: localStorage.merchantId})   
        // await this.$store.dispatch('bindOrders')
        // await this.$store.dispatch('bindCollectionByMerchant', { col: 'orders', merchantId: localStorage.merchantId})
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'shops', merchantId: localStorage.merchantId })
    },

    watch: {
        async startDate(newStartDate){
            this.$store.state.startDate = newStartDate
            await this.$store.dispatch('bindOrders')
        } ,
        async endDate(newEndDate){
            this.$store.state.endDate = newEndDate
            await this.$store.dispatch('bindOrders')
        }        
    },

    computed: {
        ...mapState({
            products: state => state.products,
            orders: state => state.orders,
            shops: state => state.shops,
        }),

        shopOptions() {
            if (!this.shops) return []
            let ret = this.shops.map(shop => {
                return {
                    text: shop.name,
                    value: {
                        id: shop.id,
                        name: shop.name,
                    }
                }
            })
            ret.unshift({ text: '全部分店', value: null })
            return ret
        },

        productByOrders() {
            if (!this.startDate) return []

            let startDate = Number(moment(this.startDate).format('YYYYMMDD'))
            let endDate = Number(moment(this.endDate ? this.endDate : this.startDate).format('YYYYMMDD'))

            let products = _.cloneDeep(this.products)
            let orders = _.cloneDeep(this.orders)

            _.forEach(products, product => {
                product.count = 0
                product.total = 0
            })
           
            for (let currentDate = startDate; currentDate <= endDate; currentDate++) {
                if (moment(String(currentDate)).format('YYYY年MM月DD日') == 'Invalid date') continue
                if (this.orders.length > 0) {
                    _.forEach(orders, order => {
                        if(this.shop){
                              if (String(order.uniNum).includes(String(currentDate)) && order.status == 'COMPLETED' && order.shop.id == this.shop.id){
                            _.forEach(order.products, product => {
                                let index = _.findIndex(products, { id: product.id })
                                if (index > -1) {
                                    if (order.method == 'PICKUP') {
                                        products[index].count += product.count
                                        products[index].total += product.total
                                    } else if (order.method == 'DELIVERY') {
                                        products[index].count += product.count
                                        products[index].total += product.total
                                    }
                                }
                            })
                        }
                        }
                        else if(String(order.uniNum).includes(String(currentDate)) && order.status == 'COMPLETED') {
                            _.forEach(order.products, product => {
                                let index = _.findIndex(products, { id: product.id })
                                if (index > -1) {
                                    if (order.method == 'PICKUP') {
                                        products[index].count += product.count
                                        products[index].total += product.total
                                    } else if (order.method == 'DELIVERY') {
                                        products[index].count += product.count
                                        products[index].total += product.total
                                    }
                                }
                            })
                        }
                        })
                }
            }
            return _.orderBy(products, ['count'], ['desc'])
        },
    },
}
</script>
